// AdminDashboard.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, Grid, Typography, TextField, MenuItem, Select, FormControl, InputLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // Obtén el token de localStorage
    console.log(token);
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;  // Agrega el token a los encabezados
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const AdminDashboard = () => {
    const [users, setUsers] = useState([]);
    const [devices, setDevices] = useState([]);
    const [mappings, setMappings] = useState([]);

    // Variables crear empresa
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [group, setGroup] = useState('');

    // variables crear dispositivo
    const [machineId, setMachineId] = useState('');
    const [company, setCompany] = useState('');
    const [email2, setEmail2] = useState('');
    const [insideLocation, setInsideLocation] = useState('');
    const [location, setLocation] = useState('');
    const [height, setHeight] = useState('');
    const [length, setLength] = useState('');
    const [width, setWidth] = useState('');
    const [distance_to_bucket, setDistance_to_bucket] = useState('');
    const [bins, setBins] = useState([]);

    // variables crear mapping
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedDevice, setSelectedDevice] = useState('');

    // Obtener usuarios, dispositivos y mapeo al cargar la página
    useEffect(() => {
        axios.get('https://api.classify-tech.com/sensors/getUsers').then(response => setUsers(response.data));
        axios.get('https://api.classify-tech.com/sensors/getDevices').then(response => setDevices(response.data));
        axios.get('https://api.classify-tech.com/sensors/getDeviceUserMapping').then(response => setMappings(response.data));
    }, []);

    // Función para crear un nuevo usuario
    const handleCreateUser = () => {
        axios.post('https://api.classify-tech.com/sensors/createUser', 
          { userName: userName,
            email: email,
            password: password,
            company_name: companyName,
            group: group

          })
            .then(() => alert('Usuario creado'))
            .catch(err => console.error('Error creando usuario:', err));
    };

    // Función para crear un nuevo dispositivo
    const handleCreateDevice = () => {
        axios.post('https://api.classify-tech.com/sensors/createDevice', 
          { 
            classifier_id: machineId,
            company:company,
            email: email2,
            inside_location: insideLocation,
            location: location,
            height: height,
            length: length,
            width: width,
            distance_to_bucket: distance_to_bucket,
            bins: bins

          })
            .then(() => alert('Dispositivo creado'))
            .catch(err => console.error('Error creando dispositivo:', err));
    };

    // Función para hacer el mapeo entre usuario y dispositivo
    const handleCreateMapping = () => {
        axios.post('https://api.classify-tech.com/sensors/companyDeviceMapping', {
            company_name: selectedUser,
            machine_id: selectedDevice,
        })
            .then(() => alert('Mapping creado'))
            .catch(err => console.error('Error creando mapping:', err));
    };

    // Función para manejar el logout
    const handleLogout = () => {
        // Elimina el token del almacenamiento local (o de donde lo guardes)
        localStorage.removeItem('token');
        // Redirige al usuario a la página de inicio de sesión
        window.location.href = '/'; // O redirige a la página de login
    };

    return (
        <div>
            <Typography variant="h3" sx={{ textAlign: 'center', margin: '20px 0' }}>Admin Dashboard</Typography>

            {/* Crear usuario */}
            <Box sx={{ marginBottom: 4 }}>
                <Typography variant="h5" gutterBottom>Crear Usuario</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="User Name"
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Nombre de la Empresa"
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Group"
                            value={group}
                            onChange={(e) => setGroup(e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Button variant="contained" color="primary" sx={{ marginTop: 2 }} onClick={handleCreateUser}>Crear Usuario</Button>
            </Box>

            {/* Crear dispositivo */}
            <Box sx={{ marginBottom: 4 }}>
                <Typography variant="h5" gutterBottom>Crear Dispositivo</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="ID del Dispositivo"
                            value={machineId}
                            onChange={(e) => setMachineId(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Nombre empresa que pertenece"
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Email personal"
                            value={email2}
                            onChange={(e) => setEmail2(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Ubicación interna de la máquina"
                            value={insideLocation}
                            onChange={(e) => setInsideLocation(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Ubicación de la empresa"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Height"
                            value={height}
                            onChange={(e) => setHeight(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Length"
                            value={length}
                            onChange={(e) => setLength(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Width"
                            value={width}
                            onChange={(e) => setWidth(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Distancia al contenedor"
                            value={distance_to_bucket}
                            onChange={(e) => setDistance_to_bucket(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Bins"
                            value={bins}
                            onChange={(e) => setBins(e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Button variant="contained" color="primary" sx={{ marginTop: 2 }} onClick={handleCreateDevice}>Crear Dispositivo</Button>
            </Box>

            {/* Crear mapeo */}
            <Box sx={{ marginBottom: 4 }}>
                <Typography variant="h5" gutterBottom>Crear Mapeo</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel>Seleccionar Usuario</InputLabel>
                            <Select value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)}>
                                {users.map((user) => (
                                    <MenuItem key={user.username} value={user.companyName}>{user.companyName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel>Seleccionar Dispositivo</InputLabel>
                            <Select value={selectedDevice} onChange={(e) => setSelectedDevice(e.target.value)}>
                                {devices.map((device) => (
                                    <MenuItem key={device.classifier_id} value={device.classifier_id}>{device.classifier_id}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Button variant="contained" color="primary" sx={{ marginTop: 2 }} onClick={handleCreateMapping}>Crear Mapeo</Button>
            </Box>

            {/* Tabla de usuarios */}
            <Typography variant="h5" sx={{ marginBottom: 2 }}>Usuarios</Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Usuario</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Nombre de Empresa</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user) => (
                            <TableRow key={user.username}>
                                <TableCell>{user.username}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{user.companyName}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Tabla de dispositivos */}
            <Typography variant="h5" sx={{ marginBottom: 2 }}>Dispositivos</Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID del Dispositivo</TableCell>
                            <TableCell>Empresa</TableCell>
                            <TableCell>Email</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {devices.map((device) => (
                            <TableRow key={device.classifier_id}>
                                <TableCell>{device.classifier_id}</TableCell>
                                <TableCell>{device.company}</TableCell>
                                <TableCell>{device.email}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Tabla de mapeos */}
            <Typography variant="h5" sx={{ marginBottom: 2 }}>Mapeos</Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID de la Empresa</TableCell>
                            <TableCell>Nombre de la Empresa</TableCell>
                            <TableCell>Máquinas</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {mappings.map((mapping) => (
                            <TableRow key={mapping.company_id}>
                                <TableCell>{mapping.company_id}</TableCell>
                                <TableCell>{mapping.company_name}</TableCell>
                                <TableCell>{mapping.machine_ids.join(', ')}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Botón de logout */}
            <Box sx={{ marginTop: 2, textAlign: 'center' }}>
                <Button variant="contained" color="secondary" onClick={handleLogout}>Logout</Button>
            </Box>
        </div>
    );
};

export default AdminDashboard;


