import React from 'react';
import { Box, Grid, Typography, Card, CardContent } from '@mui/material';
import PieDashboardComponent from './pieDashboardComponent'; // Asegúrate de importar tu componente correctamente

const HorizontalScroll = ({ title, machinesData }) => {
  return (
    <Card
      variant="outlined"
      sx={{
        borderRadius: '16px',
        backgroundColor: '#0d1f0d',
        borderColor: '#b2d235', // Borde verde claro
        padding: 0.5,
        width: '100%', // Ajuste completo del ancho
      }}
    >
      <CardContent>
        <Typography component="h1" variant="h6" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          {title}
        </Typography>
        <Box
          sx={{
            minWidth: '100%',
            display: 'flex',
            overflowX: 'scroll', // Habilitar desplazamiento horizontal
            scrollSnapType: 'x mandatory', // Para que se ajusten los gráficos mientras te desplazas
            '&::-webkit-scrollbar': { display: 'none' }, // Ocultar barra de desplazamiento (opcional)
          }}
        >
          {machinesData.map((machine, index) => (
            <Box
              key={index}
              sx={{
                marginRight: 2, // Espaciado entre gráficas
                scrollSnapAlign: 'start', // Ajuste de las gráficas en el contenedor
                minWidth: '100%'
              }}
            >
              <PieDashboardComponent
                title={`Estado de los contenedores - Máquina ${machine.id}`}
                categories={machine.categories}
              />
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default HorizontalScroll;
