import React from 'react';
import { CircularProgress, Box, Typography, Card, CardContent } from '@mui/material';

const CategoryCircle = ({ name, value, color }) => {
  return (
    <Box position="relative" display="inline-flex" margin={3} sx={{ width: '130px', height: '130px'}}>
      <CircularProgress
        variant="determinate"
        value={value}
        size={130}  // Tamaño del círculo
        thickness={7} // Grosor del círculo
        sx={{ color: color }}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h6" component="div" sx={{ color: '#fff' }}>
          {name}
        </Typography>
        <Typography variant="h6" component="div" sx={{ color: '#fff' }}>
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

const PieDashboardComponent = ({ title, categories }) => {
  return (
    <Card
      variant="outlined"
      sx={{
        borderRadius: '16px',
        backgroundColor: '#0d1f0d',
        borderColor: '#b2d235', // Borde verde claro
        padding: 1,
      }}
    >
      <CardContent>
        <Typography component="h1" variant="h6" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          {title}
        </Typography>
        <Box display="flex" justifyContent="space-around" flexWrap="wrap">
          {categories.map((category, index) => (
            <CategoryCircle
              key={index}
              name={category.name}
              value={category.value}
              color={category.color}
            />
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default PieDashboardComponent;