import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { FaHome, FaLeaf, FaCog } from 'react-icons/fa';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const Layout = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Para obtener la ruta actual
  const [selectedIndex, setSelectedIndex] = useState(0);

  // Cambiar selectedIndex basado en la ruta
  useEffect(() => {
    if (location.pathname === '/dashboard') {
      setSelectedIndex(0);
    } else if (location.pathname === '/dashboard/report') {
      setSelectedIndex(1);
    } else if (location.pathname === '/dashboard/settings') {
      setSelectedIndex(2);
    }
  }, [location.pathname]);

  const handleListItemClick = (index, path) => {
    setSelectedIndex(index);
    navigate(path);
  };

  const darkTheme = createTheme({
    palette: {
      mode: 'dark', // Modo oscuro
      background: {
        default: '#002400', // Color de fondo oscuro
        paper: '#002400', // Color de fondo para Drawer
      },
      text: {
        primary: '#FFFFFF', // Texto blanco en todo el dashboard
      },
    },
    components: {
      // Ajustar el color de fondo de Box y otros elementos principales si es necesario
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: '#002400',
            borderColor: '#BBFF42',
            borderRadius: '16px',
          },
        },
      },
    },
  });

  const imageHeight = 200; // Alto fijo para la imagen de fondo

  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
        {/* Imagen de fondo */}
        <Box
          component="img"
          sx={{
            width: '100%',
            height: `${imageHeight}px`,
            objectFit: 'cover',
            objectPosition: 'center',
          }}
          alt="Descripción de la imagen"
          src="/fondoClassify.png"
        />
        {/* Línea horizontal debajo de la imagen */}
        <Box
          sx={{
            borderTop: '1px solid #BBFF42', // Agrega una línea del mismo color verde claro
            width: '100%', // Ocupa todo el ancho de la pantalla
          }}
        />
        
        <Box sx={{ display: 'flex', flexGrow: 1, backgroundColor: '#002400' }}>
          {/* Barra lateral */}
          <Drawer
            variant="permanent"
            sx={{
              width: 180,
              flexShrink: 0,
              position: 'fixed',
              '& .MuiDrawer-paper': {
                width: 180,
                height: 190,
                boxSizing: 'border-box',
                backgroundColor: '#002400',
                border: '1px solid #BBFF42', // Agregar borde
                marginTop: '225px',
                borderRadius: '16px',
                position: 'fixed',
              },
            }}
          >
            <List>
              <ListItem disablePadding>
                <ListItemButton
                  selected={selectedIndex === 0}
                  onClick={() => handleListItemClick(0, '/dashboard')}
                >
                  <ListItemIcon>
                    <FaHome color="white" />
                  </ListItemIcon>
                  <ListItemText primary="Inicio" sx={{ color: 'white' }} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  selected={selectedIndex === 1}
                  onClick={() => handleListItemClick(1, '/dashboard/report')}
                >
                  <ListItemIcon>
                    <FaLeaf color="white" />
                  </ListItemIcon>
                  <ListItemText primary="Reportes" sx={{ color: 'white' }} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  selected={selectedIndex === 2}
                  onClick={() => handleListItemClick(2, '/dashboard/settings')}
                >
                  <ListItemIcon>
                    <FaCog color="white" />
                  </ListItemIcon>
                  <ListItemText primary="Ajustes" sx={{ color: 'white' }} />
                </ListItemButton>
              </ListItem>
            </List>
          </Drawer>

          {/* Contenido principal */}
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              bgcolor: '#002400',
              p: 3, // Ajusta padding según necesidad
              marginLeft: 0,
              color: 'white', // Asegurarse de que el texto sea vi
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </Box>
      </ThemeProvider>
  );
};

export default Layout;


