import React, { useState, useEffect } from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import './Report.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import StatCard from './StatCard';
import HorizontalScroll from './HorizontalScrollMachines';
import Typography from '@mui/material/Typography';
import RecyclingBarChart from './graficaBarras';
import TotalVolumeByHourChart from './graficaVolumenHora';
import VolumeByCategoryAndHourChart from './graficaVolumenCategorias';
import { DatePicker } from '@mui/x-date-pickers';


const Report = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [estadoActualContenedores, setEstadoActualContenedores] = useState([]);
  const [volumeDataLastMonth, setVolumeDataLastMonth] = useState([]);
  const [volumeDataDay, setVolumeDataDay] = useState([]);
  const [fullBinsData, setFullBinsData] = useState([]);
  const [fullBinsDataDay, setFullBinsDataDay] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]); // Estado para la fecha seleccionada, por defecto hoy
  const [selectedDateFullBin, setSelectedDateFullBin ] = useState(new Date().toISOString().split('T')[0]);

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value); // Actualiza la fecha seleccionada
  };

  const handleDateChangeFullBin = (e) => {
    setSelectedDateFullBin(e.target.value); // Actualiza la fecha seleccionada
  };

  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      const token = localStorage.getItem('token');
  
      if (!token) {
        console.error('No token found');
        return;
      }
  
      try {
        // Fetch de todos los datos, excepto el de volumen del día que será por fecha
        const [datosFullBinUltimoMes, datosVolumenUltimoMes, datosActualesContenedores] = await Promise.all([
          fetch('https://api.classify-tech.com/sensors/fullBinDataLastMonth', {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              Authorization: `Bearer ${token}`,
            }
          }),
          fetch('https://api.classify-tech.com/sensors/volumeDataLastMonth', {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              Authorization: `Bearer ${token}`,
            }
          }),
          fetch('https://api.classify-tech.com/sensors/lastValue', {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              Authorization: `Bearer ${token}`,
            }
          })
        ]);
  
        // Verificar que todas las respuestas sean exitosas
        if (!datosVolumenUltimoMes.ok || !datosFullBinUltimoMes.ok || !datosActualesContenedores.ok) {
          throw new Error('One or more API calls failed');
        }
  
        // Convertir las respuestas a JSON
        const [volumeDataLastMonthJson, fullBinLastMonth, actulDataConteiners] = await Promise.all([
          datosVolumenUltimoMes.json(),
          datosFullBinUltimoMes.json(),
          datosActualesContenedores.json()
        ]);
        
        setVolumeDataLastMonth(volumeDataLastMonthJson.data || []);
        setFullBinsData(fullBinLastMonth.data || []);
        setEstadoActualContenedores(actulDataConteiners.data || []);

        setLoading(false); // Dejar de mostrar el loading una vez que todos los datos se obtienen
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
        setLoading(false); // Asegurar que loading se detiene en caso de error
      }
      finally{
        setLoading(false); // Esto debe ir después de que los datos hayan sido correctamente procesados.
      }
    };
    fetchAllData();
  }, [])

  // useEffect para los datos de volumen del día, depende de selectedDate
  useEffect(() => {
    const fetchVolumeDataForSelectedDate = async () => {
      setLoading(true);
      const token = localStorage.getItem('token');
  
      if (!token) {
        console.error('No token found');
        return;
      }
  
      try {
        const datosVolumenDia = await fetch(`https://api.classify-tech.com/sensors/volumeDataToday/${selectedDate}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        });

        // Verificar que la respuesta sea exitosa
        if (!datosVolumenDia.ok) {
          throw new Error('API call for volume data failed');
        }

        const datosVolumeDay = await datosVolumenDia.json();
        console.table("Datos de volumen del día:", datosVolumeDay);
  
        setVolumeDataDay(datosVolumeDay.data || []);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching daily volume data:', error);
        setError(error.message);
        setLoading(false);
      }
    };
    fetchVolumeDataForSelectedDate();
  }, [selectedDate]); // Este useEffect se ejecuta solo cuando selectedDate cambia

  // useEffect para los datos de volumen del día, depende de selectedDateFullBin
  useEffect(() => {
    const fetchFullBinDataForSelectedDate = async () => {
      setLoading(true);
      const token = localStorage.getItem('token');
  
      if (!token) {
        console.error('No token found');
        return;
      }
  
      try {
        const datosContenedorLlenoDia = await fetch(`https://api.classify-tech.com/sensors/fullBinDataToday/${selectedDateFullBin}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        });

        // Verificar que la respuesta sea exitosa
        if (!datosContenedorLlenoDia.ok) {
          throw new Error('API call for volume data failed');
        }

        const datosFullBinDia = await datosContenedorLlenoDia.json();
        console.table("Datos cantidad de veces que se cambia la basura:", datosFullBinDia);
  
        setFullBinsDataDay(datosFullBinDia.data || []);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching daily volume data:', error);
        setError(error.message);
        setLoading(false);
      }
    };
    fetchFullBinDataForSelectedDate();
  }, [selectedDateFullBin]); // Este useEffect se ejecuta solo cuando selectedDate cambia


  const roundNumber = (num) => Math.round(num);

  const calculateDailyRecycledVolume = (data) => {
    console.log("Print de data dentro de calculateDailyRecycledVolume:", data);

    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth() + 1; // Los meses en JavaScript comienzan desde 0
    const daysInMonth = new Date(currentYear, currentMonth, 0).getDate(); // Número de días en el mes actual

    const dailyVolume = Array(daysInMonth).fill(0); // Inicializamos el array con 0 para cada día
    let totalRecycledVolume = 0; // Inicializamos el total de volumen reciclado

    // Iteramos sobre cada máquina en los datos
    Object.keys(data).forEach(machineID => {
        const machineData = data[machineID];
        
        // Iteramos sobre cada fecha en los datos de la máquina
        Object.keys(machineData).forEach(date => {
            const dateObj = new Date(date);
            const day = dateObj.getDate();

            // Verificamos que el año y el mes coincidan con el actual
            if (dateObj.getFullYear() === currentYear && (dateObj.getMonth() + 1) === currentMonth) {
                const totalVolume = machineData[date].totalVolume || 0;
                dailyVolume[day - 1] += totalVolume; // Sumamos al día correspondiente
                totalRecycledVolume += totalVolume;  // Sumamos al volumen total reciclado
            }
        });
    });

    console.log("Print de dailyVolume:", dailyVolume);
    console.log("Total de volumen reciclado:", totalRecycledVolume);

    return { dailyVolume, totalRecycledVolume }; // Devolvemos ambos valores
  };

  const {dailyVolume, totalRecycledVolume} = calculateDailyRecycledVolume(volumeDataLastMonth);
  console.log("Volume data de este mes: " + dailyVolume);

  const calculateDailyChanges = (data) => {
    console.log("Print de data dentro de calculateDailyChanges:", data);

    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth() + 1; // Los meses en JavaScript comienzan desde 0
    const daysInMonth = new Date(currentYear, currentMonth, 0).getDate(); // Número de días en el mes actual

    const dailyChanges = Array(daysInMonth).fill(0); // Inicializamos el array con 0 para cada día
    let totalChanges = 0; // Inicializamos el total de cambios de basura

    // Iteramos sobre cada máquina en los datos
    Object.keys(data).forEach(machineID => {
        const machineData = data[machineID];
        
        // Iteramos sobre cada fecha en los datos de la máquina
        Object.keys(machineData).forEach(date => {
            const dateObj = new Date(date);
            const day = dateObj.getDate();

            // Verificamos que el año y el mes coincidan con el actual
            if (dateObj.getFullYear() === currentYear && (dateObj.getMonth() + 1) === currentMonth) {
                const totalChangesForDay = machineData[date].totalChanges || 0;
                dailyChanges[day - 1] += totalChangesForDay; // Sumamos al día correspondiente
                totalChanges += totalChangesForDay;  // Sumamos al total de cambios
            }
        });
    });

    console.log("Print de dailyChanges:", dailyChanges);
    console.log("Total de cambios de basura en el mes:", totalChanges);

    return { dailyChanges, totalChanges }; // Devolvemos ambos valores
  };

  const { dailyChanges, totalChanges } = calculateDailyChanges(fullBinsData);
  console.log("Daily changes de este mes: ", dailyChanges);
  console.log("Total de cambios de basura en el mes: ", totalChanges);



  const calculateTotalVolumeByHour = (responseData) => {
    // Verificamos que responseData no sea undefined ni vacío
    if (!responseData || Object.keys(responseData).length === 0) {
        return {
          volumeByHour: Array(24).fill(0),
          volumeByCategoryAndHour: {
              plastic: Array(24).fill(0),
              organic: Array(24).fill(0),
              carton_paper: Array(24).fill(0),
              glass: Array(24).fill(0),
              mixed: Array(24).fill(0),
              aluminium: Array(24).fill(0),
          }
        };
    }

    const volumeByHour = Array(24).fill(0); // Array de 24 posiciones, inicializado en 0 para cada hora

    // Iteramos sobre cada máquina en los datos
    Object.keys(responseData).forEach(machineID => {
        const machineData = responseData[machineID];

        // Iteramos sobre cada hora de la máquina
        Object.keys(machineData).forEach(hour => {
            const hourInt = parseInt(hour, 10); // Convertimos la hora a entero
            const totalVolume = machineData[hour].totalVolume || 0;
            volumeByHour[hourInt] += totalVolume; // Sumamos el volumen a la hora correspondiente
        });
    });

    const volumeByCategoryAndHour = {
      plastic: Array(24).fill(0),
      organic: Array(24).fill(0),
      carton_paper: Array(24).fill(0),
      glass: Array(24).fill(0),
      mixed: Array(24).fill(0),
      aluminium: Array(24).fill(0),
    };

    // Iteramos sobre cada máquina en los datos
    Object.keys(responseData).forEach(machineID => {
        const machineData = responseData[machineID];

        // Iteramos sobre cada hora de la máquina
        Object.keys(machineData).forEach(hour => {
            const hourInt = parseInt(hour, 10); // Convertimos la hora a entero

            // Iteramos sobre cada categoría y sumamos el volumen a la hora correspondiente
            Object.keys(volumeByCategoryAndHour).forEach(category => {
                const volume = machineData[hour].categories[category] || 0;
                volumeByCategoryAndHour[category][hourInt] += volume;
            });
        });
    });

    return {
      volumeByHour,
      volumeByCategoryAndHour
    };
  };

  // Prueba la función y verifica el resultado
  const { volumeByHour, volumeByCategoryAndHour } = calculateTotalVolumeByHour(volumeDataDay);
  console.log("volumeByHour:", volumeByHour);
  console.log("volumeByCategoryAndHour:", volumeByCategoryAndHour);

  const calculateFullBinChangesByHour = (responseData) => {
    if (!responseData || typeof responseData !== 'object' || Object.keys(responseData).length === 0) {
      return {
        changesByHour: Array(24).fill(0),
        changesByCategoryAndHour: {
          plastic: Array(24).fill(0),
          organic: Array(24).fill(0),
          carton_paper: Array(24).fill(0),
          glass: Array(24).fill(0),
          mixed: Array(24).fill(0),
          aluminium: Array(24).fill(0),
        },
      };
    }
  
    const changesByHour = Array(24).fill(0);
    const changesByCategoryAndHour = {
      plastic: Array(24).fill(0),
      organic: Array(24).fill(0),
      carton_paper: Array(24).fill(0),
      glass: Array(24).fill(0),
      mixed: Array(24).fill(0),
      aluminium: Array(24).fill(0),
    };
  
    Object.keys(responseData).forEach(machineID => {
      const machineData = responseData[machineID];
      if (!machineData || typeof machineData !== 'object') return;
  
      Object.keys(machineData).forEach(hour => {
        const hourInt = parseInt(hour, 10);
        if (isNaN(hourInt) || hourInt < 0 || hourInt > 23) {
          console.warn(`Invalid hour: ${hour}`);
          return;
        }
  
        // Accede a totalChanges
        const totalChanges = machineData[hour]?.totalChanges || 0;
        changesByHour[hourInt] += totalChanges;
  
        // Accede a los valores directamente desde categories
        const categoryData = machineData[hour]?.categories || {};
        Object.keys(changesByCategoryAndHour).forEach(category => {
          const categoryValue = categoryData[category] || 0;
          changesByCategoryAndHour[category][hourInt] += categoryValue;
        });
      });
    });
  
    return { changesByHour, changesByCategoryAndHour };
  };  
  
  const {changesByHour, changesByCategoryAndHour} = calculateFullBinChangesByHour(fullBinsDataDay);
  console.log("changesByHour= " + changesByHour);
  console.log("changesByCategoryAndHour=" + changesByCategoryAndHour);

  const pesoRecicladoPorDia3 = [2, 4, 3, 2, 4, 5, 7, 6, 5, 4, 3, 5, 6, 7, 8, 9, 10, 12, 13, 14, 15, 14, 13, 12, 11, 13, 12, 14, 15, 17];

  const valorcontenedoresActuales = (serverData) => {
    // Mapa de colores
    const colors = {
      carton_paper: '#4caf50',
      glass: '#BBFF42',
      mixed: '#193863',
      organic: '#F4F4F4',
      plastic: '#001600',
    };
  
    // Mapa para traducir las categorías a español
    const categoriaTraducida = {
      carton_paper: 'Papel',
      glass: 'Vidrio',
      mixed: 'Mezclado',
      organic: 'Orgánico',
      plastic: 'Plástico',
    };
  
    return Object.entries(serverData).map(([machineId, categories]) => ({
      id: machineId,
      categories: Object.entries({
        carton_paper: categories.carton_paper || 0,  // Si no hay valor, poner 0
        glass: categories.glass || 0,                // Si no hay valor, poner 0
        mixed: categories.mixed || 0,                // Si no hay valor, poner 0
        organic: categories.organic || 0,            // Si no hay valor, poner 0
        plastic: categories.plastic || 0,            // Si no hay valor, poner 0
      })
        .filter(([_, value]) => value >= 0) // Filtrar solo valores no negativos
        .map(([name, value]) => ({
          name: categoriaTraducida[name] || name.replace('_', ' ').replace(/\b\w/g, (l) => l.toUpperCase()), // Traducir a español
          value: Math.round(value), // Redondear el valor
          color: colors[name] || '#000', // Color por categoría, si no tiene color predeterminado
        })),
    }));
  };
  

  const machinesData = valorcontenedoresActuales(estadoActualContenedores);
  

  return (
    <Box sx={{ backgroundColor: '#002400', minHeight: '100vh' , marginLeft: '185px'}}>

      <Grid container spacing={2}> {/* Controlar el espacio entre los elementos */}

        <Typography component="h2" variant="h2" sx={{ mb: 2 }}>
          Máquinas
        </Typography>

        <Box sx={{
          display: 'flex',
          overflowX: 'auto', // Permitir scroll horizontal
          gap: '20px', // Espacio entre las tarjetas
          padding: '10px',
          }}>
          <Grid item xs={12} md={12}>
            <HorizontalScroll 
              title="Estado actual de los contenedores por máquina" 
              machinesData={machinesData} />
          </Grid>
        </Box>
        
        <Grid item xs={12} md={12}>
          <Typography component="h2" variant="h2" sx={{ mb: 2 }}>
            Volumen de residuos
          </Typography>
        </Grid>

        <input 
        type="date" 
        value={selectedDate} 
        onChange={handleDateChange} 
        padding = "20"px
        />

        {/* Primera fila - Grafica Volumen */}
        <Grid item xs={12} md={12}>
          <Box sx={{backgroundColor: '#002400', borderRadius: 2}}>
            <TotalVolumeByHourChart
              title="Volumen total reciclado por hora"
              subtitle="Muestra el volumen total reciclado por hora entre todas las máquinas en cm3"
              data={volumeByHour || []}
            />
          </Box>
        </Grid>

        {/* Primera fila - Grafica Volumen */}
        <Grid item xs={12} md={12}>
          <Box sx={{backgroundColor: '#002400', borderRadius: 2}}>
            <VolumeByCategoryAndHourChart
                title="Volumen reciclado por hora por categoria"
                subtitle="Muestra el volumen reciclado por hora por categoría entre todas las máquinas en cm3"
                data={volumeByCategoryAndHour}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={12}>
          <Typography component="h2" variant="h2" sx={{ mb: 2 }}>
            Cambios de basura
          </Typography>
        </Grid>
        
        {/* Seleccionamos fecha para los datos de cuanto se cambia la basura */}
        <input 
        type="date" 
        value={selectedDateFullBin} 
        onChange={handleDateChangeFullBin} 
        />

        {/* Primera fila - Grafica Volumen */}
        <Grid item xs={12} md={12}>
          <Box sx={{backgroundColor: '#002400', borderRadius: 2}}>
            <TotalVolumeByHourChart
              title="Numero de veces que se cambia la basura por dia "
              subtitle="Se muestra el detalle por hora en total"
              data={changesByHour || []}
            />
          </Box>
        </Grid>

        {/* Primera fila - Grafica Volumen */}
        <Grid item xs={12} md={12}>
          <Box sx={{backgroundColor: '#002400', borderRadius: 2}}>
            <VolumeByCategoryAndHourChart
                title="Cambios de basura por hora y categoría"
                subtitle="Se muestra el detalle por hora y categoría"
                data={changesByCategoryAndHour}
            />
          </Box>
        </Grid>

        {/* Primera fila - StatCards */}
        <Grid item xs={12} md={3}>
          <Box sx={{backgroundColor: '#002400', borderRadius: 2}}>
            <StatCard
              title="Volumen de residuos reciclados"
              value={roundNumber(totalRecycledVolume)}
              interval="Últimos 30 días"
              trend="up"
              data = {dailyVolume}
              month= {Number(new Date().getMonth() + 1)}
              year={Number(new Date().getFullYear())}
            />
          </Box>
        </Grid>

        {/* Primera fila - StatCards */}
        <Grid item xs={12} md={3}>
          <Box sx={{backgroundColor: '#002400', borderRadius: 2}}>
            <StatCard
              title="Cantidad de veces que se llenaron los contenedores"
              value={roundNumber(totalChanges)}
              interval="Últimos 30 días"
              trend="up"
              data = {dailyChanges}
              month= {Number(new Date().getMonth() + 1)}
              year={Number(new Date().getFullYear())}
            />
          </Box>
        </Grid>
  
        
      </Grid>
    </Box>
  );
};

export default Report;
