import React from 'react';
import { Card, CardContent, Typography, useTheme } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export default function TotalVolumeByHourChart({ title, subtitle, data }) {
    const theme = useTheme();

    const chartData = data.map((volume, index) => ({
        hour: index,
        volume: volume,
    }));

    return (
        <Card
            variant="outlined"
            sx={{
                height: '100%',
                borderRadius: '16px',
                backgroundColor: '#0d1f0d',
                borderColor: '#b2d235',
            }}
        >
            <CardContent>
                <Typography component="h1" variant="title" gutterBottom sx={{ color: '#fff' }}>
                    {title}
                </Typography>
                <Typography variant="body2" sx={{ color: '#fff', marginBottom: 2 }}>
                    {subtitle}
                </Typography>
                <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={chartData}>
                        <CartesianGrid strokeDasharray="3 3" stroke="#fff" />
                        <XAxis dataKey="hour" tick={{ fill: '#fff' }} />
                        <YAxis tick={{ fill: '#fff' }} />
                        <Tooltip contentStyle={{ backgroundColor: '#333', borderColor: '#333' }} />
                        <Line type="monotone" dataKey="volume" stroke="#BBFF42" strokeWidth={2} />
                    </LineChart>
                </ResponsiveContainer>
            </CardContent>
        </Card>
    );
}