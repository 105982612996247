import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { BarChart } from '@mui/x-charts/BarChart';
import { useTheme } from '@mui/material/styles';

export default function VolumeByCategoryAndHourChart({ title, subtitle, data }) {
  const theme = useTheme();

  // Paleta de colores para las categorías
  const colorPalette = [
    '#F4F4F4',    // Vidrio
    '#BBFF42',    // Plástico
    '#7DA2CF',    // Papel y cartón
    '#193863',    // Orgánicos
    '#001600',    // Aluminio
  ];

  // Verifica si los datos están disponibles
  if (!data || Object.keys(data).length === 0) {
    return <Typography variant="h6" color="textSecondary">Cargando datos...</Typography>;
  }

  // Asegúrate de que cada categoría tenga 24 valores (uno para cada hora)
  const categories = ["plastic", "organic", "carton_paper", "glass", "mixed", "aluminium"];
  const formattedData = categories.map(category => ({
    id: category,
    label: category.charAt(0).toUpperCase() + category.slice(1),
    data: Array.from({ length: 24 }, (_, hour) => data[category]?.[hour] || 0)
  }));

  return (
    <Card
      variant="outlined"
      sx={{
        height: '100%',
        borderRadius: '16px',
        backgroundColor: '#0d1f0d',
        borderColor: '#b2d235',
      }}
    >
      <CardContent>
        <Typography component="h1" variant="title" gutterBottom sx={{ color: '#fff' }}>
          {title}
        </Typography>
        <Typography variant="caption" sx={{ color: '#fff', marginBottom: 2 }}>
          {subtitle}
        </Typography>
        <BarChart
          borderRadius={8}
          colors={colorPalette}
          xAxis={[
            {
              borderColor: '#fff',
              scaleType: 'band',
              categoryGapRatio: 0.5,
              data: Array.from({ length: 24 }, (_, hour) => `${hour}:00`), // Eje X con las horas
            },
          ]}
          series={formattedData}
          height={300}
          margin={{ left: 50, right: 0, top: 20, bottom: 40 }}
          grid={{ horizontal: true }}
        />
      </CardContent>
    </Card>
  );
}
