import React, { useState } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import './LoginModal.css';
import { Auth } from 'aws-amplify';

Modal.setAppElement('#root');

const LoginModal = ({ isOpen, onRequestClose, onLoginSuccess }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(''); // Reset error message

    try {
      const user = await Auth.signIn(email, password); // Intenta hacer login
      const idToken = user.getSignInUserSession().getIdToken().getJwtToken();
      localStorage.setItem("token", idToken); // Guardar el token en el localStorage

      // Llamamos a onLoginSuccess() para actualizar el estado de isLoggedIn en App.js
      onLoginSuccess();

      // Decodificar el token para obtener el grupo
      const decodedToken = JSON.parse(atob(idToken.split('.')[1]));
      const userGroups = decodedToken["cognito:groups"]; // Obtener los grupos del payload

      if (userGroups && userGroups.includes("Admins")) {
        navigate("/admin"); // Redirige al admin si es del grupo "Admins"
      } else if (userGroups && userGroups.includes("Clientes")) {
        navigate("/dashboard"); // Redirige al dashboard si es del grupo "Clientes"
      } else {
        setError("No tienes acceso a esta aplicación.");
        localStorage.removeItem("token"); // Elimina el token si el grupo no es válido
      }
    } catch (err) {
      console.error("Error de autenticación:", err);
      setError("Credenciales incorrectas. Intenta nuevamente.");
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="modal"
      overlayClassName="overlay"
    >
      <div className="login-modal">
        <h2>Login</h2>
        {error && <p className="error">{error}</p>}
        <form onSubmit={handleLogin}>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit">Login</button>
        </form>
      </div>
    </Modal>
  );
};

export default LoginModal;
