const awsmobile = {
    Auth: {
        mandatorySignIn: true,
        region: 'us-east-1', // Por ejemplo: 'us-east-1'
        userPoolId: 'us-east-1_zsBHmkY0I',
        userPoolWebClientId: '52tue37vfcunlhqjl69psal7ee',
      },
  };
  
  export default awsmobile;
  