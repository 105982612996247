import React, { useState } from 'react';
import { Box, Typography, Grid, TextField, Button } from '@mui/material';
import { Auth } from 'aws-amplify';

const Settings = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    currentPassword: '',
    newPassword: '',
  });

  const [feedback, setFeedback] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleChangePassword = async () => {
    try {
      await Auth.changePassword(
        await Auth.currentAuthenticatedUser(),
        formData.currentPassword,
        formData.newPassword
      );
      setFeedback('Contraseña cambiada exitosamente.');
    } catch (error) {
      setFeedback(`Error al cambiar la contraseña: ${error.message}`);
    }
  };

  const handleUpdateEmail = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, { email: formData.email });
      setFeedback('Correo actualizado exitosamente.');
    } catch (error) {
      setFeedback(`Error al actualizar el correo: ${error.message}`);
    }
  };

  const handleUpdateUsername = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, { preferred_username: formData.username });
      setFeedback('Nombre de usuario actualizado exitosamente.');
    } catch (error) {
      setFeedback(`Error al actualizar el nombre de usuario: ${error.message}`);
    }
  };

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      localStorage.removeItem('token');
      window.location.href = '/'; // Redirige a la página principal
    } catch (error) {
      setFeedback(`Error al cerrar sesión: ${error.message}`);
    }
  };

  return (
    <Box sx={{ backgroundColor: '#002400', minHeight: '100vh', marginLeft: '185px', p: 4 }}>
      <Typography component="h2" variant="h4" color="#BBFF42" sx={{ mb: 4 }}>
        Configuración
      </Typography>

      <Grid container spacing={4}>
        {/* Cambiar nombre de usuario */}
        <Grid item xs={12} md={6}>
          <TextField
            label="Nuevo nombre de usuario"
            name="username"
            value={formData.username}
            onChange={handleInputChange}
            fullWidth
            variant="outlined"
            InputLabelProps={{ style: { color: '#BBFF42' } }}
            sx={{
              backgroundColor: '#002400',
              borderRadius: 2,
              input: { color: 'white' },
              border: '1px solid #BBFF42',
            }}
          />
          <Button
            onClick={handleUpdateUsername}
            variant="contained"
            sx={{ mt: 2, backgroundColor: '#BBFF42', color: '#002400' }}
          >
            Actualizar nombre de usuario
          </Button>
        </Grid>

        {/* Cambiar correo electrónico */}
        <Grid item xs={12} md={6}>
          <TextField
            label="Nuevo correo electrónico"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            fullWidth
            variant="outlined"
            InputLabelProps={{ style: { color: '#BBFF42' } }}
            sx={{
              backgroundColor: '#002400',
              borderRadius: 2,
              input: { color: 'white' },
              border: '1px solid #BBFF42',
            }}
          />
          <Button
            onClick={handleUpdateEmail}
            variant="contained"
            sx={{ mt: 2, backgroundColor: '#BBFF42', color: '#002400' }}
          >
            Actualizar correo electrónico
          </Button>
        </Grid>

        {/* Cambiar contraseña */}
        <Grid item xs={12} md={6}>
          <TextField
            label="Contraseña actual"
            name="currentPassword"
            value={formData.currentPassword}
            onChange={handleInputChange}
            type="password"
            fullWidth
            variant="outlined"
            InputLabelProps={{ style: { color: '#BBFF42' } }}
            sx={{
              backgroundColor: '#002400',
              borderRadius: 2,
              input: { color: 'white' },
              border: '1px solid #BBFF42',
            }}
          />
          <TextField
            label="Nueva contraseña"
            name="newPassword"
            value={formData.newPassword}
            onChange={handleInputChange}
            type="password"
            fullWidth
            variant="outlined"
            InputLabelProps={{ style: { color: '#BBFF42' } }}
            sx={{
              backgroundColor: '#002400',
              borderRadius: 2,
              input: { color: 'white' },
              border: '1px solid #BBFF42',
              mt: 2,
            }}
          />
          <Button
            onClick={handleChangePassword}
            variant="contained"
            sx={{ mt: 2, backgroundColor: '#BBFF42', color: '#002400' }}
          >
            Cambiar contraseña
          </Button>
        </Grid>

        {/* Cerrar sesión */}
        <Grid item xs={12}>
          <Button
            onClick={handleLogout}
            variant="contained"
            sx={{ mt: 2, backgroundColor: 'red', color: 'white' }}
          >
            Cerrar sesión
          </Button>
        </Grid>
      </Grid>

      {feedback && (
        <Typography color="#BBFF42" sx={{ mt: 4 }}>
          {feedback}
        </Typography>
      )}
    </Box>
  );
};

export default Settings;

