import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Header from './components/Header';
import Home from './components/Home';
import Services from './components/Services';
import AboutUs from './components/AboutUs';
import ValueChain from './components/ValueChain';
import ContactUs from './components/ContactUs';
import SupportedBy from './components/SupportedBy';
import Dashboard from './dashBoardComponents/Dashboard';
import Report from './dashBoardComponents/Report';
import Settings from './dashBoardComponents/Settings';
import Layout from './dashBoardComponents/Layout';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import AdminDashboard from './adminComponents/AdminDashboard';
Amplify.configure(awsconfig);

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  if (!token) {
    return <Navigate to="/" />;
  }
  return children;
};
const PrivateAdminRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  const userGroups = JSON.parse(atob(token.split('.')[1]))['cognito:groups'] || [];
  
  if (!token || !userGroups.includes('Admins')) {
    return <Navigate to="/" />;
  }
  return children;
};

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userGroup, setUserGroup] = useState(null); // Para almacenar el grupo del usuario
  const navigate = useNavigate();
  const location = useLocation(); // Para obtener la ruta actual

  useEffect(() => {
    const checkSession = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const decodedToken = JSON.parse(atob(token.split('.')[1]));
          const userGroups = decodedToken["cognito:groups"];
          
          if (userGroups && userGroups.includes("Admins")) {
            setUserGroup("Admins");
            navigate("/admin");
          } else if (userGroups && userGroups.includes("Clientes")) {
            setUserGroup("Clientes");
            navigate(location.pathname);
          } else {
            setUserGroup(null);
            navigate("/");
          }
          setIsLoggedIn(true);
        } catch (error) {
          console.error("Error al verificar el token", error);
          setIsLoggedIn(false);
          navigate("/");
        }
      } else {
        setIsLoggedIn(false);
        navigate("/");
      }
    };

    checkSession();
  }, [navigate]);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  return (
      <div>
        <Routes>
          <Route path="/" element={
            <>
              <Header onLoginSuccess={handleLogin} />
              <Home />
              <Services />
              <ValueChain />
              <AboutUs />
              <ContactUs />
              <SupportedBy />
            </>
          } />

          {/* Rutas para dashboard de cliente */}
          {isLoggedIn && (
            <Route path="/dashboard" element={<PrivateRoute><Layout /></PrivateRoute>}>
              <Route index element={<Dashboard />} />
              <Route path="report" element={<Report />} />
              <Route path="settings" element={<Settings />} />
            </Route>
          )}

          {/* Ruta protegida para la página de administración */}
          {isLoggedIn && (
            <Route path="/admin" element={<PrivateAdminRoute><AdminDashboard /></PrivateAdminRoute>} />
          )}

          {/* Redirección en caso de rutas no autorizadas */}
          {!isLoggedIn && (
            <Route path="*" element={<Navigate to="/" />} />
          )}
        </Routes>
      </div>
  );
}

export default App;
